import { ENVIRONMENT_SHARED } from 'environment';
import { OptionGroup } from 'ui/components/Dropdown/types';
import API from 'utils/api/API';
import { Paginated } from 'utils/types/common';
import { ValidationResult } from '../helpers/codeValidation';

export type FreightForwarderType = 'head-office' | 'group' | 'branch';

export type FreightForwarderSingleOption = {
	name: string;
	code: string;
	itemType: FreightForwarderType;
	freightForwarderId: `${FreightForwarderType}-${string}`;
};

export type FreightForwarderCustomGroupOption = {
	itemType: 'custom-group';
	name: string;
	freightForwarderId: `custom-group-${string}`;
	code: string;
};

export type FreightForwarderOption =
	| FreightForwarderSingleOption
	| FreightForwarderCustomGroupOption;

export type FreightForwarderExportItem = {
	id: string;
	createdAt: Date;
	startedAt?: Date;
	completedAt?: Date;
	exportFileName?: string;
	exportFileSize?: string;
	exportFileUrl?: string;
	exportFileSizeInBytes?: number;
	status: string;
	failureMessage?: string;
};

export type FreightForwarderGroup = {
	itemType: FreightForwarderType;
} & OptionGroup<FreightForwarderSingleOption>;

export type GetFreightForwardersOptionsResponse = {
	keyword: string;
	options: FreightForwarderSingleOption[];
	groups: null;
};

export type GetFreightForwardersByGroupResponse = {
	keyword: string;
	options: null;
	groups: FreightForwarderGroup[];
};

export type GetFreightForwardersResponse =
	| GetFreightForwardersOptionsResponse
	| GetFreightForwardersByGroupResponse;

export type ValidateFreightFrowarderCodesResponse =
	ValidationResult<FreightForwarderSingleOption>;

export type GetFreightForwarderExportsResponse = {
	exports: Paginated<FreightForwarderExportItem>;
};

export type CreateFreightForwarderExportsResponse = {
	Id: string;
};

export type GenerateFreightForwarderUploadLinkResponse = {
	uploadLink: string;
};

class FreightForwarderAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getFreightForwarders(
		keyword: string,
		types: FreightForwarderType[],
		forSubscriptionId?: string
	) {
		const params = new URLSearchParams();
		params.append('Keyword', keyword);

		for (const type of types) {
			params.append('type', type.toString());
		}

		if (forSubscriptionId) {
			params.append('subscriptionId', forSubscriptionId);
		}

		return FreightForwarderAPI.get<GetFreightForwardersResponse>(
			`/freight-forwarders/lookup?${params.toString()}`
		);
	}

	static async validateFreightForwarderCodes(
		codes: string[],
		types: FreightForwarderType[]
	) {
		const params = new URLSearchParams();

		params.append('codes', codes.join(','));

		for (const type of types) {
			params.append('type', type.toString());
		}

		return FreightForwarderAPI.get<ValidateFreightFrowarderCodesResponse>(
			`/freight-forwarders/validate?${params.toString()}`
		);
	}

	static getFreightForwarderExports() {
		return FreightForwarderAPI.get<GetFreightForwarderExportsResponse>(
			`/freight-forwarders/exports`
		);
	}

	static createAndRunExport() {
		return FreightForwarderAPI.post<CreateFreightForwarderExportsResponse>(
			`/freight-forwarders/exports/create`,
			{}
		);
	}

	static async generateUploadLink(filename: string) {
		return FreightForwarderAPI.post<GenerateFreightForwarderUploadLinkResponse>(
			`/freight-forwarders/exports/upload-link/${filename}`
		);
	}

	static async uploadFile(
		apiUrl: string,
		data: BodyInit | null | undefined
	): Promise<Response> {
		const customHeaders: { [key: string]: string } = {};

		customHeaders['Content-Type'] = 'multipart/form-data';

		const requestInit: RequestInit = {
			method: 'PUT',
			body: data,
		};

		requestInit.headers = { ...requestInit.headers, ...customHeaders };

		return await fetch(apiUrl, requestInit);
	}
}

export default FreightForwarderAPI;
