import { getWeek, lastDayOfMonth, Locale } from 'date-fns';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { enGB } from 'date-fns/locale';
import React, { useMemo, useState } from 'react';
import ReactDatepicker, {
	ReactDatePickerCustomHeaderProps,
} from 'react-datepicker';
import { DateUnit } from 'utils/api/WebToolAPI';
import { POPPER_MODIFIER } from '../DatePicker/DatePicker';
import DatePickerHeader from '../DatePicker/DatePickerHeader';
import MonthPickerHeader from '../MonthPicker/MonthPickerHeader';
import useFormReset from '../ValidatedForm/useFormReset';

function iataFormatWeekNumber(dt: Date) {
	return (
		'W' + getWeek(dt, WEEK_PICKER_LOCALE.options).toString().padStart(2, '0')
	);
}

export const WEBTOOL_DATE_FORMATS: { [key in DateUnit]: string } = {
	day: 'dd MMM yyyy',
	week: "'W'ww YYYY",
	month: 'MMMM yyyy',
};

const WEBTOOL_DATE_HEADERS: {
	[key in DateUnit]: (
		params: ReactDatePickerCustomHeaderProps
	) => React.ReactNode;
} = {
	day: DatePickerHeader,
	week: DatePickerHeader,
	month: MonthPickerHeader,
};

export const WEEK_START = { weekStartsOn: 1 } as const;

/* enGB used to enforce weeks starting on Monday */
export const WEEK_PICKER_LOCALE: Locale = {
	...enGB,
	options: {
		...WEEK_START,
		firstWeekContainsDate: 7,
	},
};

export type WebToolDatePickerProps = {
	value?: Date | null;

	name?: string;
	onChange?: (date: Date | null) => void;
	initialValue?: Date;
	id?: string;
	isDisabled?: boolean;
	placeholder?: string;

	minDate?: Date;
	maxDate?: Date;

	pickerPeriod: DateUnit;
	monthPickerUseLastDay?: boolean;
};

const WebToolDatePicker = ({
	value: controlledSelectedDate,

	name,
	onChange: onConntrolledSelectedDateChange,
	id,
	initialValue,
	isDisabled,
	placeholder,

	minDate,
	maxDate,

	pickerPeriod,
	monthPickerUseLastDay,
}: WebToolDatePickerProps) => {
	const [uncontrolledSelectedDate, setUncontrolledSelectedDate] =
		useState<Date | null>(initialValue ?? null);

	const selectedDate = controlledSelectedDate ?? uncontrolledSelectedDate;
	const onSelectedDateChange =
		onConntrolledSelectedDateChange ?? setUncontrolledSelectedDate;

	const clampedSelectedDate = useMemo(() => {
		if (!selectedDate) return null;

		if (minDate && selectedDate < minDate) {
			return minDate;
		}

		if (maxDate && selectedDate > maxDate) {
			return maxDate;
		}

		return utcToZonedTime(selectedDate, 'UTC');
	}, [selectedDate, minDate, maxDate]);

	const formattedValue = useMemo(() => {
		if (!clampedSelectedDate) return null;

		const dummyDate = new Date(clampedSelectedDate);
		dummyDate.setDate(1);

		return formatInTimeZone(dummyDate, 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'");
	}, [clampedSelectedDate]);

	useFormReset(() => {
		onSelectedDateChange(null);
	});

	const timezoneAdjustedMinDate = useMemo(() => {
		// The minDate comes down as UTC, but we need to treat it as the according local time
		// when passing it to the datepicker

		// e.g. Jan 1st 2022 00:00 UTC is Dec 31st 2021 19:00 in PST,
		// but we need to pass it as Jan 1st 2022 00:00 PST to the datepicker

		return minDate ? utcToZonedTime(minDate, 'UTC') : null;
	}, [minDate]);

	const timezoneAdjustedMaxDate = useMemo(() => {
		// See above

		return maxDate ? utcToZonedTime(maxDate, 'UTC') : null;
	}, [maxDate]);

	return (
		<>
			<ReactDatepicker
				id={id}
				selected={clampedSelectedDate}
				onChange={(date) => {
					if (!date) {
						onSelectedDateChange(null);
						return;
					}

					if (pickerPeriod === 'month' && monthPickerUseLastDay === true) {
						date = lastDayOfMonth(date);
					}

					const utcDate = zonedTimeToUtc(date, 'UTC');
					onSelectedDateChange(utcDate);
				}}
				customInput={<input className="control__input" />}
				minDate={timezoneAdjustedMinDate}
				maxDate={timezoneAdjustedMaxDate}
				renderCustomHeader={WEBTOOL_DATE_HEADERS[pickerPeriod]}
				locale={WEEK_PICKER_LOCALE}
				dateFormat={WEBTOOL_DATE_FORMATS[pickerPeriod]}
				dateFormatCalendar={WEBTOOL_DATE_FORMATS[pickerPeriod]}
				formatWeekNumber={iataFormatWeekNumber}
				disabled={isDisabled}
				placeholderText={placeholder}
				popperModifiers={POPPER_MODIFIER}
				showMonthYearPicker={pickerPeriod === 'month'}
				showWeekPicker={pickerPeriod === 'week'}
				showWeekNumbers={pickerPeriod === 'week'}
			/>
			{formattedValue && name && (
				<input type="hidden" name={name} value={formattedValue} />
			)}
		</>
	);
};

export default WebToolDatePicker;
