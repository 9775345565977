import { CSSProperties, useMemo } from 'react';
import Flex from 'ui/components/Flex/Flex';
import SelectionButton from 'ui/components/SelectionButton/SelectionButton';
import useFormReset from 'ui/components/ValidatedForm/useFormReset';
import {
	WORKSHEET_DATA_FIELD_DIMENSIONS,
	WORKSHEET_DATA_FIELD_DIMENSIONS_KEYS,
	WORKSHEET_DATA_FIELD_DIMENSIONS_SHORT,
	WorksheetConfigCurrency,
	WorksheetConfigDistortionUi,
	WorksheetConfigFieldGroup,
	WorksheetDataFieldTimeSeriesState,
} from 'utils/api/WebToolAPI';
import { sortBySortOrder } from 'utils/helpers/sorting';
import WorksheetCheckboxGroup from './CheckboxGroup/WorksheetCheckboxGroup';
import WorksheetDataPicker from './DataPicker/WorksheetDataPicker';
import { useWorksheetContext } from './WorksheetContext';
import WorksheetSection from './WorksheetSection';

type WorksheetOutputProps = {};

const WorksheetOutput = ({}: WorksheetOutputProps) => {
	const {
		worksheet,
		state,
		setDeepState,
		isOutputPanelOpen,
		setIsOutputPanelOpen,
		derivedState,
	} = useWorksheetContext();

	const { disabledFields, disabledDataFieldMetrics } = derivedState;

	useFormReset(() => {
		const defaultCurrency = worksheet.config.currencies.find(
			(c) => c.defaultSelected
		);

		setDeepState('output.currency', [defaultCurrency?.id ?? 'USD']);
	});

	// Partition data fields into 3 groups based on order
	const partitionedFieldGroups = useMemo(
		() =>
			[...worksheet.config.fieldGroups]
				.sort(sortBySortOrder)
				.reduce<WorksheetConfigFieldGroup[][]>(
					([group1, group2, group3], dataField, index) => {
						if (index % 3 === 0) {
							return [[...group1, dataField], group2, group3];
						} else if (index % 3 === 1) {
							return [group1, [...group2, dataField], group3];
						} else {
							return [group1, group2, [...group3, dataField]];
						}
					},
					[[], [], []]
				),
		[worksheet.config.fieldGroups]
	);

	const handleCurrencyChange = (currency: WorksheetConfigCurrency) => {
		const previousCurrency = state.output.currency[0];

		// If the only option is the selected one, then return
		if (
			state.output.currency.length === 1 &&
			previousCurrency === currency.id
		) {
			return;
		}

		// If the selection does exist in the array, then remove it
		if (state.output.currency.includes(currency.id)) {
			setDeepState(
				'output.currency',
				state.output.currency.filter((c) => c !== currency.id)
			);
			return;
		}

		// If the selection does not exist in the array, then add it
		setDeepState('output.currency', [...state.output.currency, currency.id]);
	};

	const handleDistortionChange = (
		distortionOption: WorksheetConfigDistortionUi
	) => {
		const previousDistortion = state.output.distortion?.[0];

		// If the only option is the selected one, then return
		if (
			state.output.distortion.length === 1 &&
			previousDistortion === distortionOption.id
		) {
			return;
		}

		// If the selection does exist in the array, then remove it
		if (state.output.distortion.includes(distortionOption.id)) {
			setDeepState(
				'output.distortion',
				state.output.distortion.filter((d) => d !== distortionOption.id)
			);
			return;
		}

		// If the selection does not exist in the array, then add it
		setDeepState('output.distortion', [
			...state.output.distortion,
			distortionOption.id,
		]);
	};

	const isAdvanced = state.output.advancedMode;

	const activeDimensions = useMemo(() => {
		const dimensions = WORKSHEET_DATA_FIELD_DIMENSIONS_SHORT;

		if (!worksheet.config.dataFieldTimeSeries.weekOverWeek) {
			// Only keep items 1-4
			return dimensions.slice(0, 4);
		}

		return dimensions;
	}, [worksheet.config.dataFieldTimeSeries.weekOverWeek]);

	return (
		<WorksheetSection
			title="Report Output"
			style={{ '--parameters-grid': '1fr 1fr 1fr auto' } as CSSProperties}
			advancedModeEnabled={isAdvanced}
			isOpen={isOutputPanelOpen}
			onOpenChange={setIsOutputPanelOpen}
			onAdvancedModeEnabledChange={() => {
				setDeepState('output.advancedMode', !isAdvanced);
			}}
		>
			{partitionedFieldGroups.map((fieldGroups) => (
				<Flex direction="column" gap={24} key={fieldGroups[0].name}>
					{fieldGroups
						.filter((fieldGroup) =>
							isAdvanced ? true : !fieldGroup.isAdvanced
						)
						.map((fieldGroup) => (
							<WorksheetCheckboxGroup
								key={fieldGroup.name}
								name={fieldGroup.name}
								disabledFields={disabledFields}
								options={
									isAdvanced
										? fieldGroup.fields
										: fieldGroup.fields.filter((field) => !field.isAdvanced)
								}
							/>
						))}
				</Flex>
			))}

			<Flex direction="column" gap={20}>
				{state.output.advancedMode === false && (
					<Flex direction="column" gap={8}>
						<b>Data Period</b>
						<Flex direction="row" gap={8}>
							{activeDimensions.map((dimension, dimensionIndex) => (
								<SelectionButton
									key={dimension}
									checked={
										state.output.dataFieldTimeSeries.simple[dimensionIndex]
									}
									hint={WORKSHEET_DATA_FIELD_DIMENSIONS[dimensionIndex]}
									disabled={disabledDataFieldMetrics.includes(
										WORKSHEET_DATA_FIELD_DIMENSIONS_KEYS[dimensionIndex]
									)}
									onChange={(e) => {
										const newValue: WorksheetDataFieldTimeSeriesState = [
											...state.output.dataFieldTimeSeries.simple,
										];
										newValue[dimensionIndex] = !newValue[dimensionIndex];

										if (newValue.some(Boolean)) {
											setDeepState(
												'output.dataFieldTimeSeries.simple',
												newValue
											);
										}
									}}
								>
									{dimension}
								</SelectionButton>
							))}
						</Flex>
					</Flex>
				)}

				<WorksheetDataPicker isAdvanced={isAdvanced} />

				<Flex gap={20}>
					{worksheet.config.currencies.length > 0 && (
						<Flex direction="column" gap={8}>
							<b>Currency</b>
							<Flex direction="row" gap={8}>
								{worksheet.config.currencies.map((currency) => (
									<SelectionButton
										key={currency.id}
										checked={state.output.currency.includes(currency.id)}
										onChange={(e) => handleCurrencyChange(currency)}
										disabled={disabledFields['payment-currency-code']}
									>
										{currency.label}
									</SelectionButton>
								))}
							</Flex>
						</Flex>
					)}
					{worksheet.config.distortionOptions.length > 0 && (
						<Flex direction="column" gap={8}>
							<b>Distortion</b>
							<Flex direction="row" gap={8}>
								{worksheet.config.distortionOptions.map((option) => (
									<SelectionButton
										key={option.id}
										checked={state.output.distortion.includes(option.id)}
										onChange={() => handleDistortionChange(option)}
									>
										{option.label}
									</SelectionButton>
								))}
							</Flex>
						</Flex>
					)}
				</Flex>
			</Flex>
		</WorksheetSection>
	);
};

export default WorksheetOutput;
